export const GET_JOBS_LIST = null;
export const GET_JOB_TYPE_SELECT_LIST = null;
export const GET_JOB_ID = null;
export const GET_CUSTOMER_NAME_SELECT_LIST = null;
export const GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST = null;
export const GET_ALL_STATES_SELECT_LIST = null;
export const GET_CITY_BY_STATE_ID_SELECT_LIST = null;
export const GET_ALL_JOB_TIMELINE_LIST = null;
export const GET_ALL_JOB_MAP_LIST = null;
export const SELECTED_JOB_ID = null;
export const GET_GOOGLE_MAPS_GEOCODE_LIST = null;
