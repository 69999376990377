import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import { translate } from "../../i18n/i18n";

export const useOperationsMenuUtils = () => {
  const tempMenuGroup = [];

  /* menuItemOperationsDashboard */
  const menuItemOperationsDashboard = {
    id: "operationsDashboard",
    title: AppModuleConfig.OPERATIONS_MODULES.DASHBOARD,
    url: AppRoutingConfig.APP_URL_OPERATIONS_DASHBOARD,
  };
  tempMenuGroup.push(menuItemOperationsDashboard);
  /* menuItemOperationsDashboard */

  /* menuItemOperationsCustomerManagement */
  const menuItemOperationsCustomerManagement = {
    id: "customerManagement",
    title: AppModuleConfig.OPERATIONS_MODULES.CUSTOMERS,
    url: AppRoutingConfig.APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST,
  };
  tempMenuGroup.push(menuItemOperationsCustomerManagement);
  /* menuItemOperationsCustomerManagement */

  /* menuItemOperationsProjectSummary */
  const menuItemOperationsProjectSummary = {
    id: "projectSummary",
    title: AppModuleConfig.OPERATIONS_MODULES.PROJECT_SUMMARY,
    url: AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST,
  };
  tempMenuGroup.push(menuItemOperationsProjectSummary);
  /* menuItemOperationsProjectSummary */

  /* menuItemOperationsWorkFlow */
  const menuItemOperationsWorkFlow = {
    id: "workFlow",
    title: AppModuleConfig.OPERATIONS_MODULES.WORK_FLOW,
    url: AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST,
  };
  tempMenuGroup.push(menuItemOperationsWorkFlow);
  /* menuItemOperationsWorkFlow */

  /* menuItemSchedule */
  const menuItemSchedule = {
    id: "workFlow",
    title: AppModuleConfig.OPERATIONS_MODULES.SCHEDULE,
    url: AppRoutingConfig.APP_URL_OPERATIONS_SCHEDULE_MODULE_LIST,
  };
  tempMenuGroup.push(menuItemSchedule);
  /* menuItemSchedule */

  return tempMenuGroup;
};
