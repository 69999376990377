import AppApiConfig from "../../../assets/config/AppApiConfig";
import axiosConfigInstance from "../../../axios/axiosInstance";
import { AddJobTypes } from "./scheduleBoardServices";

export const getJobs = (dataToBeSent: {
  orderBy: boolean;
  pageNumber: number;
  pageSize: number;
  searchString: string;
  searchByJobStatus: null;
  searchByJobType: null;
  searchByIsPriority: boolean;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_JOB_MODULE_LIST_URL,
    dataToBeSent
  );
};

export const getJobTypeSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_JOB_TYPE_SELECT_LIST_URL
  );
};

export const getJobId = () => {
  return axiosConfigInstance.post(AppApiConfig.API_GENERATE_JOB_ID_URL);
};

export const getCustomerNameSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_CUSTOMER_NAME_SELECT_LIST_URL
  );
};

export const getProjectByCustomerSelectList = (dataToBeSent: {
  customerId: number;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST_URL,
    dataToBeSent
  );
};

export const getAllStatesSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_ALL_STATES_SELECT_LIST_URL
  );
};

export const getCityByStateIdList = (dataToBeSent: { StateId: number }) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_CITY_BY_STATE_ID_SELECT_LIST_URL,
    {},
    { params: dataToBeSent }
  );
};

export const addJob = (dataToBeSent: AddJobTypes) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_ADD_EDIT_JOB_URL,
    dataToBeSent
  );
};

export const getJobById = (dataToBeSent: { JobId: string }) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_JOB_BY_ID_URL,
    {},
    { params: dataToBeSent }
  );
};

export const getAllJobTimelineList = (dataToBeSent: {
  startDate: string | null;
  endDate: string | null;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GET_ALL_JOB_TIMELINE_LIST_URL,
    dataToBeSent
  );
};

export const getAllJobMapList = () => {
  return axiosConfigInstance.post(AppApiConfig.API_GET_ALL_JOB_MAP_LIST_URL);
};

export const deleteJob = (dataToBeSent: { JobId: number }) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_DELETE_JOB_MODULE_URL,
    dataToBeSent
  );
};

export const getGoogleMapsGeoCode = (dataToBeSent: {
  latitude: number;
  longitude: number;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GOOGLE_MAPS_GEOCODE_URL,
    dataToBeSent
  );
};

export const getGoogleMapsPlaceId = (dataToBeSent: { placeId: string }) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GOOGLE_MAPS_PLACE_DETAILS_URL,
    dataToBeSent
  );
};
