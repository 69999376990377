import { translate } from "../../i18n/i18n";

export default class AppModuleConfig {
  static ADMIN = translate("AppModules.Admin");
  static HR = translate("AppModules.HR");
  static OPERATIONS = translate("AppModules.Operations");
  static SALES = translate("AppModules.Sales");
  static CRM = translate("AppModules.Crm");
  static FLEET = translate("AppModules.Fleet");
  static FINANCE = translate("AppModules.Finance");
  static SCHEDULE_BOARD = translate("AppModules.ScheduleBoard");

  static ADMIN_MODULES = {
    DASHBOARD: translate("AppModules.AdminSubModules.AdminDashboard"),
    REGION: translate("AppModules.AdminSubModules.Region"),
    DEPARTMENT: translate("AppModules.AdminSubModules.Department"),
    CUSTOMERS: translate("AppModules.AdminSubModules.Customers"),
    VENDORS: translate("AppModules.AdminSubModules.Vendors"),
    PARTNERS: translate("AppModules.AdminSubModules.Partners"),
    ACTIVITY: translate("AppModules.AdminSubModules.Activity"),
  };

  static HR_MODULES = {
    DASHBOARD: translate("AppModules.HrSubModules.HrDashboard"),
    EMPLOYEE_MANAGEMENT: translate(
      "AppModules.HrSubModules.EmployeeManagement"
    ),
    DOCUMENT_MANAGEMENT: translate(
      "AppModules.HrSubModules.DocumentManagement"
    ),
    ATTENDANCE_MANAGEMENT: translate(
      "AppModules.HrSubModules.AttendanceManagement"
    ),
    LEAVE_MANAGEMENT: translate("AppModules.HrSubModules.LeaveManagement"),
    PAYROLL_MANAGEMENT: translate("AppModules.HrSubModules.PayrollManagement"),
  };

  static OPERATIONS_MODULES = {
    DASHBOARD: translate("AppModules.OperationsSubModules.OperationsDashboard"),
    CUSTOMERS: translate("AppModules.OperationsSubModules.Customers"),
    PROJECT_SUMMARY: translate(
      "AppModules.OperationsSubModules.ProjectSummary"
    ),
    WORK_FLOW: translate("AppModules.OperationsSubModules.WorkFlow"),
    SCHEDULE: translate("AppModules.OperationsSubModules.Schedule"),
  };

  static SALES_MODULES = {
    CUSTOMERS: translate("AppModules.SalesSubModules.Customers"),
    LEAD: translate("AppModules.SalesSubModules.Lead"),
    QUOTES: translate("AppModules.SalesSubModules.Quotes"),
    PROJECTS: translate("AppModules.SalesSubModules.Projects"),
    SALES_REP: translate("AppModules.SalesSubModules.SalesRep"),
  };

  static CRM_MODULES = {
    DASHBOARD: translate("AppModules.CrmSubModules.CrmDashboard"),
    LEADPOOL: translate("AppModules.CrmSubModules.Leadpool"),
    AGENTS: translate("AppModules.CrmSubModules.Agents"),
    CAMPAIGNS: translate("AppModules.CrmSubModules.Campaigns"),
  };

  static FLEET_MODULES = {
    DASHBOARD: translate("AppModules.FleetSubModules.FleetDashboard"),
    FLEET_MANAGEMENT: translate("AppModules.FleetSubModules.FleetManagement"),
  };

  static FINANCE_MODULES = {
    DASHBOARD: translate("AppModules.FinanceSubModules.FinanceDashboard"),
  };

  static SCHEDULE_BOARD_MODULES = {
    INFO: translate("AppModules.ScheduleBoardModules.Info"),
  };

  static getModuleNamesArray() {
    return [
      AppModuleConfig.ADMIN,
      AppModuleConfig.HR,
      AppModuleConfig.OPERATIONS,
      AppModuleConfig.SALES,
      AppModuleConfig.CRM,
      AppModuleConfig.FLEET,
      AppModuleConfig.FINANCE,
      AppModuleConfig.SCHEDULE_BOARD,
    ];
  }
}
