import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";

export const useScheduleBoardMenuUtils = () => {
  const tempMenuGroup = [];

  /* menuItemInfo */
  const menuItemInfo = {
    id: "info",
    title: AppModuleConfig.SCHEDULE_BOARD_MODULES.INFO,
    url: AppRoutingConfig.APP_URL_SCHEDULE_BOARD_INFO,
  };
  tempMenuGroup.push(menuItemInfo);
  /* menuItemInfo */

  return tempMenuGroup;
};
