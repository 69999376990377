import Actions from "../actions";
import { produce } from "immer";

type JobMapListTypes = {
  latitude: string;
  longitude: string;
  isDefault: boolean;
  id: number;
  jobNo: string;
  clientId: number;
  clientName: string;
  projectId: number;
  projectName: string;
  jobStartDate: string;
  jobEndDate: string;
  clientAddress: string;
  jobStatus: string;
};

interface State {
  jobs: {
    totalCount: number;
    jobDetails: [];
  };
  jobsSelectList: [];
  jobId: string;
  customerNameSelectList: [];
  projectSelectList: [];
  statesSelectList: [];
  citiesSelectList: [];
  jobMapList: {
    jobMapDetails: JobMapListTypes[];
  };
  jobMapListMarkers: any[];
  selectedJobId: number;
  googleMapsGeoCodeList: [];
}
const initialState: State = {
  jobs: {
    totalCount: 0,
    jobDetails: [],
  },
  jobsSelectList: [],
  jobId: "",
  customerNameSelectList: [],
  projectSelectList: [],
  statesSelectList: [],
  citiesSelectList: [],
  jobMapList: {
    jobMapDetails: [
      {
        latitude: "",
        longitude: "",
        isDefault: false,
        id: 0,
        jobNo: "",
        clientId: 0,
        clientName: "",
        projectId: 0,
        projectName: "",
        jobStartDate: "",
        jobEndDate: "",
        clientAddress: "",
        jobStatus: "",
      },
    ],
  },
  jobMapListMarkers: [],
  selectedJobId: 0,
  googleMapsGeoCodeList: [],
};

const jobs = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_JOBS_LIST: {
      draft.jobs = payload?.jobs;
      break;
    }
    case Actions.GET_JOB_TYPE_SELECT_LIST: {
      draft.jobsSelectList = payload?.jobsSelectList;
      break;
    }
    case Actions.GET_JOB_ID: {
      draft.jobId = payload;
      break;
    }
    case Actions.SELECTED_JOB_ID: {
      draft.selectedJobId = payload?.selectedJobId;
      break;
    }
    case Actions.GET_CUSTOMER_NAME_SELECT_LIST: {
      draft.customerNameSelectList = payload?.customerNameSelectList;
      break;
    }
    case Actions.GET_ALL_STATES_SELECT_LIST: {
      draft.statesSelectList = payload?.statesSelectList;
      break;
    }
    case Actions.GET_CITY_BY_STATE_ID_SELECT_LIST: {
      draft.citiesSelectList = payload?.citiesSelectList;
      break;
    }
    case Actions.GET_GOOGLE_MAPS_GEOCODE_LIST: {
      draft.googleMapsGeoCodeList = payload?.googleMapsGeoCodeList;
      break;
    }
    case Actions.GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST: {
      draft.projectSelectList = payload?.projectSelectList?.map(
        (element: { id: number; projectName: string; amount: number }) => {
          return {
            ...element,
            label: element?.projectName,
            value: element?.id,
          };
        }
      );
      break;
    }
    case Actions.GET_ALL_JOB_MAP_LIST: {
      draft.jobMapList = payload?.jobMapList;
      draft.jobMapListMarkers = payload?.jobMapList?.jobMapDetails?.map(
        (element: JobMapListTypes) => {
          return {
            lat: Number(element?.latitude),
            lng: Number(element?.longitude),
            ...element,
          };
        }
      );
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default jobs;
