export const jobStatusSelectList = [
  {
    label: "Unassigned",
    value: "Unassigned",
    backgroundColor: "var(--vivid-orange-color)",
  },
  {
    label: "Assigned",
    value: "Assigned",
    backgroundColor: "var(--dark-red-color)",
  },
  {
    value: "In Progress",
    label: "In Progress",
    backgroundColor: "var(--primary-color)",
  },
  {
    label: "On Hold",
    value: "On Hold",
    backgroundColor: "var(--slate-blue)",
  },
  {
    label: "Completed",
    value: "Completed",
    backgroundColor: "var(--caribbean-green)",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
    backgroundColor: "var(--cotton-candy-pink-gradient)",
  },
];
