import { AxiosResponse } from "axios";

/* function definition to get iso date to custom date object formats */
export const isoToDispViewDateObj = (date: Date) => {
  let formattedDate = null;

  if (date && date !== null && date !== undefined) {
    formattedDate = new Date(date);
  }
  return formattedDate;
};
/* function definition to get iso date to custom date object formats */

/* function definition to get iso date to custom date formats */
export const isoToDispViewDate = (isoDate: Date | string, type: string) => {
  let formattedDate = "";

  if (isoDate && isoDate !== null && isoDate !== undefined) {
    const date = new Date(isoDate);

    const year = String(date.getFullYear());
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    formattedDate = getDateBasedOnFormatType(day, month, year, type);
  }

  return formattedDate;
};

/* function definition to get iso date to custom date formats */

/* function definition to get the dates based on its type */
const getDateBasedOnFormatType = (
  day: string,
  month: string,
  year: string,
  type: string
) => {
  let formattedDate = "";
  switch (type) {
    case "ddmmyyyy":
      formattedDate = day + "-" + month + "-" + year;
      break;
    case "mmddyyyy":
      formattedDate = month + "-" + day + "-" + year;
      break;
    case "yyyymmdd":
      formattedDate = year + "-" + month + "-" + day;
      break;
    case "yyyyddmm":
      formattedDate = year + "-" + day + "-" + month;
      break;
    default:
      break;
  }
  return formattedDate;
};
/* function definition to get the dates based on its type */

/* function definition to get custom date with month name */
export const isoToDispViewCustomDate = (isoDate: Date | null) => {
  let tempFormattedDate = "";
  if (isoDate && isoDate !== null && isoDate !== undefined) {
    const formattedDate = isoToDispViewDate(isoDate, "ddmmyyyy");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const tempDateArr = formattedDate.split("-");
    const day = tempDateArr[0];
    const monthIndex = parseInt(tempDateArr[1]) - 1;
    const year = tempDateArr[2];

    tempFormattedDate = day + " " + months[monthIndex] + " " + year;
  }

  return tempFormattedDate;
};
/* function definition to get custom date with month name */

/* function definition to convert time into iso */
export const isoToDispViewTime = (isoDate: string) => {
  let formattedTime = "";

  if (isoDate && isoDate !== "") {
    const date = new Date(isoDate);
    let hours = date.getHours();
    let minutes: number | string = date.getMinutes();

    let suffix = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    formattedTime = hours + ":" + minutes + " " + suffix;
  }
  return formattedTime;
};
/* function definition to convert time into iso */

/* function definition to convert 24 hour time format into 12 hour format */
export const dispViewTimeInto12HourFormat = (time: string) => {
  let formattedTime = "";

  if (time && time !== "") {
    const [hours, minutes] = time.split(":");
    let tempHours = parseInt(hours);
    let tempMinutes: number | string = parseInt(minutes);

    let suffix = tempHours >= 12 ? "pm" : "am";

    tempHours = tempHours % 12 || 12;
    tempMinutes = minutes;

    formattedTime = tempHours + ":" + tempMinutes + " " + suffix;
  }
  return formattedTime;
};
/* function definition to convert 24 hour time format into 12 hour format */

/* function definition to convert date to iso */
export const dateToDispViewIsoFormat = (date: string) => {
  let formattedDate = "";
  if (date && date !== "" && date !== null && date !== undefined) {
    const tempDate = new Date(date);
    const offset = tempDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const adjustedDate = new Date(tempDate.getTime() - offset);
    formattedDate = adjustedDate.toISOString();
  }

  return formattedDate;
};
/* function definition to convert date to iso */

/* function definition to convert date object to iso */
export const dateObjToDispViewIsoFormat = (date: Date | null) => {
  let formattedDate = null;
  if (date && date !== null && date !== undefined) {
    const tempDate = new Date(date);
    const offset = tempDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const adjustedDate = new Date(tempDate.getTime() - offset);
    formattedDate = adjustedDate.toISOString();
  }

  return formattedDate;
};
/* function definition to convert date object to iso */

export const dateObjToDispViewIsoFormatTest = (date: Date | null) => {
  if (date && date !== null && date !== undefined) {
    const pad = (n: number) => {
      return n < 10 ? "0" + n : n;
    };
    return (
      date.getUTCFullYear() +
      "-" +
      pad(date.getUTCMonth() + 1) +
      "-" +
      pad(date.getUTCDate()) +
      "T" +
      pad(date.getUTCHours()) +
      ":" +
      pad(date.getUTCMinutes()) +
      ":" +
      pad(date.getUTCSeconds()) +
      "Z"
    );
  }
};

/* function definition to convert time into hours */
export const timeToDispViewHours = (time: string) => {
  let formattedHours = "";

  if (time && time !== "") {
    const [hours, minutes] = time.split(":");

    let totalHours: any = (parseInt(hours) + parseInt(minutes)) / 60;
    totalHours = parseFloat(totalHours).toFixed(2);

    formattedHours = totalHours + " " + "hr";
  }

  return formattedHours;
};
/* function definition to convert time into hours */

/* function definition to convert date to timestamp */
export const dateToTimestamp = (dateStr: any) => {
  let dobTs = 0;
  if (dateStr && dateStr !== undefined && dateStr !== "") {
    var isValidDateTs = checkIfDateIsTimestamp(dateStr);

    if (isValidDateTs === false) {
      const dateObj = new Date(dateStr);
      dateObj.setHours(0);
      dateObj.setMinutes(0);
      dateObj.setSeconds(0);
      dateObj.setMilliseconds(0);
      dobTs = dateObj.getTime() / 1000;
    } else {
      dobTs = dateStr * 1;
    }
  }
  return dobTs;
};

function checkIfDateIsTimestamp(dateStr: any) {
  let isValidDateTs = false;
  if (dateStr && dateStr !== undefined && !isNaN(dateStr)) {
    isValidDateTs = new Date(dateStr).getTime() > 0;
  }
  return isValidDateTs;
}
/* function definition to convert date to timestamp */

/* function definition to subtract years from date */
export const subtractYearsFromDate = (date: Date, years: number) => {
  const tempDate = new Date(date);

  tempDate.setFullYear(tempDate.getFullYear() - years);

  return tempDate;
};
/* function definition to subtract years from date */

/* function definition to get the difference between two dates */
export const getDifferenceBetweenTwoDates = (
  dateFrom: string,
  dateTo: string
) => {
  let diffDays = 0;
  const tempDateFrom: any = new Date(dateFrom);
  const tempDateTo: any = new Date(dateTo);
  const diffTime = Math.abs(tempDateFrom - tempDateTo);
  if (!isNaN(diffTime)) diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays.toString();
};
/* function definition to get the difference between two dates */

/* function definition to get the difference between two dates */
export const getDifferenceBetweenTwoDatesIncludingCurrentDate = (
  dateFrom: string | Date,
  dateTo: string | Date
) => {
  let diffDays = 0;
  const tempDateFrom: any = new Date(dateFrom);
  const tempDateTo: any = new Date(dateTo);
  const diffTime = Math.abs(tempDateFrom - tempDateTo);
  if (!isNaN(diffTime))
    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  return diffDays.toString();
};
/* function definition to get the difference between two dates */

/* function definition to check if the object is empty */
export const isObjectEmpty = (object: any) => {
  return (
    object && Object.keys(object).length === 0 && object.constructor === Object
  );
};

/* function definition to check if the object is empty */

/* function definition to get the filtered object from id */
export const getFilteredObjectFromId = (
  id: number | string,
  list: { value: any; label: string }[]
) => {
  return new Promise((resolve, reject) => {
    const result =
      list?.find(
        (element: { value: any; label: string }) => element.value === id
      ) || null;
    if (result) {
      resolve(result);
    }
  });
};
/* function definition to get the filtered object from id */

/* function definition to get the filtered object from label */
export const getFilteredObjectFromString = (
  string: number | string,
  list: { value: any; label: string }[]
) => {
  return new Promise((resolve, reject) => {
    const result =
      list?.find(
        (element: { value: any; label: string }) => element.label === string
      ) || null;
    if (result) {
      resolve(result);
    }
  });
};
/* function definition to get the filtered object from label */

/* function definition to get the filtered array for multi react select to set the default value */
export const getFilteredArrayForMultiSelect = (
  arrayOfId: number[],
  arrayOfObject: { value: any; label: string }[]
) => {
  return new Promise((resolve, reject) => {
    const refactoredArrayOfId = arrayOfId?.map((id: number) => {
      const result =
        arrayOfObject?.find(
          (element: { value: any; label: string }) => element.value === id
        ) || null;
      return result;
    });
    if (refactoredArrayOfId && refactoredArrayOfId.length !== 0) {
      resolve(refactoredArrayOfId);
    }
  });
};
/* function definition to get the filtered array for multi react select to set the default value */

/* function definition to check if the reponse is valid */
export const isResponseSuccessfull = (response: AxiosResponse<any> | null) => {
  return response && response.status === 200;
};

export const checkForValidResponse = (apiRes: AxiosResponse<any> | null) => {
  if (isResponseSuccessfull(apiRes)) {
    const res = apiRes?.data;
    if (res) return res;
  }
};

export const checkForValidResp = (apiRes: AxiosResponse<any> | null) => {
  return new Promise((resolve, reject) => {
    if (apiRes?.status === 200) {
      const response = apiRes?.data;
      if (response.statusCode === 200) {
        const tempResponse = response.data as any;
        resolve(tempResponse);
      } else {
        reject(response);
      }
    }
  });
};
/* function definition to check if the reponse is valid */

/* function definition to get random string from array */
export const getRandomStringFromArray = (arrayOfString: string[]) => {
  const lengthOfArray = arrayOfString?.length;
  const randomArrayIndex = Math.floor(Math.random() * lengthOfArray);
  return arrayOfString[randomArrayIndex];
};
/* function definition to get random string from array */

/* function definition to add comma, decimal and dollar in string */
export const addCommaDollarAndDecimalInNumber = (number: string) => {
  let formattedNumber = "";
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  formattedNumber = `$ ${Number(number).toLocaleString("en-US", options)}`;

  return formattedNumber;
};

export const formatTime = (tempDuration: number) => {
  const getSeconds = `0${tempDuration % 60}`.slice(-2);
  const minutes = Math.floor(tempDuration / 60);
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(tempDuration / 3600)}`.slice(-2);

  return Number(getHours) > 0
    ? `${getHours}:${getMinutes}:${getSeconds}`
    : `${getMinutes}:${getSeconds}`;
};

export const generateRandomGradient = () => {
  const randomColorValue = () => Math.floor(Math.random() * 151) + 50;
  const color = `rgb(${randomColorValue()}, ${randomColorValue()}, ${randomColorValue()})`;
  return color;
};
