import { combineReducers } from "redux";
import auth from "./authReducer";
import department from "./departmentReducer";
import loadingScreen from "./loadingScreenReducer";
import region from "./regionReducer";
import projectSummary from "./projectSummaryReducer";
import workFlow from "./workFlowReducer";
import employeeManagement from "./employeeManagementReducer";
import documentManagement from "./documentManagementReducer";
import attendanceManagement from "./attendanceManagementReducer";
import leaveManagement from "./leaveManagementReducer";
import customerManagement from "./customerManagementReducer";
import campaigns from "./campaignReducer";
import lead from "./leadReducer";
import common from "./commonReducer";
import quotes from "./quotesReducer";
import agents from "./agentReducer";
import driverCertification from "./driverCertificationReducer";
import fleetManagement from "./fleetManagementReducer";
import crmDashboard from "./crmDashboardReducer";
import salesRep from "./salesRepReducer";
import vendors from "./vendorsReducer";
import partners from "./partnersReducer";
import finance from "./financeReducer";
import hrDashboard from "./hrDashboardReducer";
import adminDashboard from "./adminDashboardReducer";
import activity from "./activityReducer";
import activityField from "./activityFieldReducer";
import operationsDashboard from "./operationsDashboardReducer";
import scheduleBoard from "./scheduleBoardReducer";

const rootReducer = () =>
  combineReducers({
    auth,
    loadingScreen,
    department,
    region,
    projectSummary,
    workFlow,
    customerManagement,
    employeeManagement,
    documentManagement,
    attendanceManagement,
    leaveManagement,
    campaigns,
    lead,
    common,
    quotes,
    driverCertification,
    fleetManagement,
    agents,
    crmDashboard,
    salesRep,
    vendors,
    partners,
    finance,
    hrDashboard,
    adminDashboard,
    activity,
    activityField,
    operationsDashboard,
    scheduleBoard,
  });

export default rootReducer;
