export default class AppRoutingConfig {
  /* GENERAL */
  static APP_URL_DEFAULT_HOME = "/";
  static APP_URL_AUTH_USER_ACCESS_DENIED = "/403";
  static APP_URL_AUTH_PAGE_NOT_FOUND = "/404";
  /* GENERAL */

  /* USER SESSION */
  static APP_URL_SIGN_IN = "/sign-in";
  static APP_URL_LOGOUT_LANDING = "/sign-out";
  static APP_URL_FORGOT_PASSWORD = "/forgot-password";
  static APP_URL_OTP = "/otp";
  static APP_URL_RESET_PASSWORD = "/reset-password";
  static APP_URL_USER_PROFILE = "/profile";
  /* USER SESSION */

  /* ADMIN MODULE */
  static APP_URL_ADMIN = "/admin";
  static APP_URL_ADMIN_DASHBOARD = "/admin/dashboard";

  /** REGION MODULE **/
  static APP_URL_ADMIN_REGION_MODULE_LIST = "/admin/region";
  static APP_URL_MOD_ADMIN_REGION_MODULE = "/admin/region/mod";
  /** REGION MODULE **/

  /** DEPARTMENT MODULE **/
  static APP_URL_ADMIN_DEPARTMENT_MODULE_LIST = "/admin/department";
  /** DEPARTMENT MODULE **/

  /** VENDORS MODULE **/
  static APP_URL_ADMIN_VENDORS_MODULE_LIST = "/admin/vendors";
  static APP_URL_MOD_ADMIN_VENDORS_MODULE = "/admin/vendors/mod";
  static APP_URL_ADMIN_VENDORS_MODULE_DETAIL = "/admin/vendors/detail";
  /** VENDORS MODULE **/

  /** PARTNERS MODULE **/
  static APP_URL_ADMIN_PARTNERS_MODULE_LIST = "/admin/partners";
  static APP_URL_MOD_ADMIN_PARTNERS_MODULE = "/admin/partners/mod";
  static APP_URL_ADMIN_PARTNERS_MODULE_DETAIL = "/admin/partners/detail";
  /** PARTNERS MODULE **/

  /* CUSTOMERS MODULE */
  static APP_URL_ADMIN_CUSTOMERS_MODULE_LIST = "/admin/customers";
  static APP_URL_MOD_ADMIN_CUSTOMERS_MODULE = "/admin/customers/mod";
  static APP_URL_ADMIN_CUSTOMERS_MODULE_DETAIL = "/admin/customers/detail";
  /* CUSTOMERS MODULE */

  /* ACTIVITY CATEGORIES MODULE */
  static APP_URL_ADMIN_ACTIVITY_MODULE_LIST = "/admin/activity";
  static APP_URL_MOD_ADMIN_ACTIVITY_MODULE = "/admin/activity/mod";
  static APP_URL_ADMIN_ACTIVITY_MODULE_DETAIL = "/admin/activity/detail";
  /* ACTIVITY CATEGORIES MODULE */

  /* ADMIN MODULE */

  /* OPERATIONS MODULE */
  static APP_URL_OPERATIONS = "/operations";
  static APP_URL_OPERATIONS_DASHBOARD = "/operations/dashboard";

  /** PROJECT SUMMARY MODULE **/
  static APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST =
    "/operations/projectSummary";
  static APP_URL_MOD_OPERATIONS_PROJECT_SUMMARY_MODULE =
    "/operations/projectSummary/mod";
  static APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_DETAIL =
    "/operations/projectSummary/detail";
  /** PROJECT SUMMARY MODULE **/

  /** WORK FLOW MODULE **/
  static APP_URL_PROJECT_SCHEDULING_MODULE_LIST = "/operations/workFlow";
  static APP_URL_PROJECT_SCHEDULING_MODULE = "/operations/workFlow/mod";
  static APP_URL_PROJECT_SCHEDULING_MODULE_DETAIL =
    "/operations/workFlow/detail";
  static APP_URL_FIELD_DATA_MODULE_LIST = "/operations/workFlow/fieldData";
  static APP_URL_ADD_FIELD_DATA_MODULE = "/operations/workFlow/fieldData/mod";
  /** WORK FLOW MODULE **/

  /** SCHEDULE MODULE **/
  static APP_URL_OPERATIONS_SCHEDULE_MODULE_LIST = "/operations/schedule";
  static APP_URL_OPERATIONS_SCHEDULE_MODULE_MOD = "/operations/schedule/mod";
  /** SCHEDULE MODULE **/

  /** CUSTOMER MANAGEMENT MODULE **/
  static APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST =
    "/operations/customerManagement";
  static APP_URL_MOD_OPERATIONS_CUSTOMER_MODULE =
    "/operations/customerManagement/mod";
  static APP_URL_OPERATIONS_CUSTOMER_MODULE_DETAIL =
    "/operations/customerManagement/detail";
  /** CUSTOMER MANAGEMENT MODULE **/
  /* OPERATIONS MODULE */

  /* HR MODULE */
  static APP_URL_HR = "/hr";
  static APP_URL_HR_DASHBOARD = "/hr/dashboard";

  /** EMPLOYEE MANAGEMENT MODULE **/
  static APP_URL_HR_EMPLOYEE_MANAGEMENT_MODULE_LIST = "/hr/employeeManagement";
  static APP_URL_MOD_HR_EMPLOYEE_MANAGEMENT_MODULE =
    "/hr/employeeManagement/mod";
  static APP_URL_MOD_ADD_HR_EMPLOYEE_MANAGEMENT_PRIMARY_MODULE =
    "/hr/employeeManagement/mod/primary";
  static APP_URL_MOD_ADD_HR_EMPLOYEE_MANAGEMENT_SECONDARY_MODULE =
    "/hr/employeeManagement/mod/secondary";
  /** EMPLOYEE MANAGEMENT MODULE **/

  /** DOCUMENT MANAGEMENT MODULE **/
  static APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST = "/hr/documentManagement";
  static APP_URL_ADD_HR_DOCUMENT_MANAGEMENT_MODULE =
    "/hr/documentManagement/add";
  static APP_URL_EDIT_HR_DOCUMENT_MANAGEMENT_MODULE =
    "/hr/documentManagement/edit";
  static APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_DETAIL =
    "/hr/documentManagement/detail";
  /** DOCUMENT MANAGEMENT MODULE **/

  /** ATTENDANCE MANAGEMENT MODULE **/
  static APP_URL_HR_ATTENDANCE_MANAGEMENT_MODULE_LIST =
    "/hr/attendanceManagement";
  static APP_URL_MOD_HR_ATTANDANCE_MANAGEMENT_MODULE =
    "/hr/attendanceManagement/mod";
  /** ATTENDANCE MANAGEMENT MODULE **/

  /** LEAVE MANAGEMENT MODULE **/
  static APP_URL_HR_LEAVE_MANAGEMENT_MODULE_LIST = "/hr/leaveManagement";
  /** LEAVE MANAGEMENT MODULE **/

  /** PAYROLL MANAGEMENT MODULE **/
  static APP_URL_HR_PAYROLL_MANAGEMENT_MODULE_LIST = "/hr/payrollManagement";
  static APP_URL_MOD_HR_PAYROLL_MODULE = "/hr/payrollManagement/mod";
  /** PAYROLL MANAGEMENT MODULE **/

  /* HR MODULE */

  /* SALES MODULE */
  static APP_URL_SALES = "/sales";
  static APP_URL_SALES_DASHBOARD = "/sales/dashboard";

  /* CUSTOMERS MODULE */
  static APP_URL_SALES_CUSTOMERS_MODULE_LIST = "/sales/customers";
  static APP_URL_MOD_SALES_CUSTOMERS_MODULE = "/sales/customers/mod";
  static APP_URL_SALES_CUSTOMERS_MODULE_DETAIL = "/sales/customers/detail";
  /* CUSTOMERS MODULE */

  /* LEAD MODULE */
  static APP_URL_SALES_LEAD_MODULE_LIST = "/sales/lead";
  static APP_URL_SALES_ADD_LEAD_MODULE = "/sales/lead/mod";
  static APP_URL_SALES_LEAD_DETAIL_MODULE = "/sales/lead/detail";
  /* LEAD MODULE */

  /* QUOTES MODULE */
  static APP_URL_SALES_QUOTES_MODULE_LIST = "/sales/quotes";
  static APP_URL_MOD_SALES_QUOTES_MODULE = "/sales/quotes/mod";
  static APP_URL_SALES_QUOTES_MODULE_DETAIL = "/sales/quotes/detail";
  /* QUOTES MODULE */

  /* PROJECTS MODULE */
  static APP_URL_SALES_PROJECTS_MODULE_LIST = "/sales/projects";
  static APP_URL_MOD_SALES_PROJECTS_MODULE = "/sales/projects/mod";
  static APP_URL_SALES_PROJECTS_MODULE_DETAIL = "/sales/projects/detail";
  /* PROJECTS MODULE */

  /* SALES REP MODULE */
  static APP_URL_SALES_REP_MODULE_LIST = "/sales/salesRep";
  static APP_URL_MOD_SALES_REP_MODULE = "/sales/salesRep/mod";
  /* SALES REP MODULE */
  /* SALES MODULE */

  /* CRM MODULE */

  static APP_URL_CRM = "/crm";
  static APP_URL_CRM_DASHBOARD = "/crm/dashboard";

  /* LEADPOOL MODULE */
  static APP_URL_CRM_LEADPOOL_MODULE_LIST = "/crm/leadPool";
  /* LEADPOOL MODULE */

  /* AGENTS MODULE */
  static APP_URL_CRM_AGENTS_MODULE_LIST = "/crm/agents";
  /* AGENTS MODULE */

  /* CAMPAIGNS MODULE */
  static APP_URL_CRM_CAMPAIGNS_MODULE_LIST = "/crm/campaigns";
  static APP_URL_MOD_CRM_CAMPAIGNS_MODULE = "/crm/campaigns/mod";
  static APP_URL_CRM_CAMPAIGNS_MODULE_DETAIL = "/crm/campaigns/detail";
  /* CAMPAIGNS MODULE */

  /* CRM MODULE */

  /* FLEET MODULE */

  static APP_URL_FLEET = "/fleet";
  static APP_URL_FLEET_DASHBOARD = "/fleet/dashboard";

  /* FLEET MANAGEMENT MODULE */
  static APP_URL_FLEET_FLEET_MANAGEMENT_MODULE_LIST = "/fleet/fleetManagement";
  static APP_URL_MOD_FLEET_FLEET_MANAGEMENT_MODULE =
    "/fleet/fleetManagement/mod";
  static APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_VEHICLE_INFORMATION_MODULE =
    "/fleet/fleetManagement/mod/vehicleInformation";
  static APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_MAINTENANCE_AND_REPAIRS_MODULE =
    "/fleet/fleetManagement/mod/maintenanceAndRepairs";

  static APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_FUEL_MANAGEMENT_MODULE =
    "/fleet/fleetManagement/mod/fuelManagement";

  static APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_DRIVER_INFORMATION_MODULE =
    "/fleet/fleetManagement/mod/driverInformation";

  static APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_TRIP_MANAGEMENT_MODULE =
    "/fleet/fleetManagement/mod/tripManagement";
  /* FLEET MANAGEMENT MODULE */

  /* FLEET MODULE */

  /* FINANCE MODULE */
  static APP_URL_FINANCE = "/finance";
  static APP_URL_FINANCE_DASHBOARD = "/finance/dashboard";
  /* FINANCE MODULE */

  /* LEAVE MODULE */
  static APP_URL_LEAVE = "/leave";
  static APP_URL_LEAVE_MANAGEMENT_MODULE_LIST = "/leave/leaveManagement";
  /* LEAVE MODULE */

  /* SCHEDULE BOARD MODULE */
  static APP_URL_SCHEDULE_BOARD = "/scheduleboard";
  static APP_URL_SCHEDULE_BOARD_INFO = this.APP_URL_SCHEDULE_BOARD + "/info";
  static APP_URL_MOD_SCHEDULE_BOARD_INFO_MODULE =
    this.APP_URL_SCHEDULE_BOARD_INFO + "/mod";
  /* SCHEDULE BOARD MODULE */
}
