import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";
import AddJobsForm from "./AddJobsForm";

const Info = lazy(() => import("./Info"));

const InfoConfig = {
  routes: [
    {
      index: true,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_SCHEDULE_BOARD_INFO} replace />
      ),
    },
    {
      path: AppRoutingConfig.APP_URL_SCHEDULE_BOARD_INFO,
      element: <Info />,
      handle: { crumb: () => "Info" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_SCHEDULE_BOARD_INFO_MODULE,
      element: <AddJobsForm />,
      handle: { crumb: () => "Add Jobs" },
    },
  ],
};

export default InfoConfig;
