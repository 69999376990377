import React from "react";
import "./Grid.scss";

type Cols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Spacing = "sm" | "md" | "lg";
type JustifyContent =
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "flex-start"
  | "flex-end";
type AlignItems = "center" | "flex-start" | "flex-end";
type FlexDirection = "row" | "column";
type TextAlign = "start" | "center" | "end";

type Props = {
  children: React.ReactNode;
  container?: boolean;
  item?: boolean;
  cols?: Cols;
  xs?: Cols;
  sm?: Cols;
  md?: Cols;
  lg?: Cols;
  spacing?: Spacing;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  flexDirection?: FlexDirection;
  textAlign?: TextAlign;
  gap?: string;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Grid = ({
  children,
  container,
  item,
  cols,
  xs,
  sm,
  md,
  lg,
  spacing,
  justifyContent,
  alignItems,
  flexDirection,
  textAlign,
  gap,
  className,
  ...props
}: Props) => {
  return (
    <div
      className={`
    ${container ? "grid-container" : ""}
    ${container && flexDirection ? `grid-flexDirection-${flexDirection}` : ""} 
    ${item ? "grid-item" : ""} 
    ${item && textAlign ? `grid-textAlign-${textAlign}` : ""}
    ${cols ? `grid-cols-${cols}` : ""}
    ${gap ? `grid-gap-${gap}` : ""}
    ${xs ? `grid-xs-${xs}` : ""}
    ${sm ? `grid-sm-${sm}` : ""}
    ${md ? `grid-md-${md}` : ""}
    ${lg ? `grid-lg-${lg}` : ""}
    ${spacing ? `grid-spacing-${spacing}` : ""}
    ${justifyContent ? `grid-justifyContent-${justifyContent}` : ""}
    ${alignItems ? `grid-alignItems-${alignItems}` : ""}
    ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Grid;
