import {
  CrmIcon,
  DashboardIcon,
  FleetIcon,
  HRIcon,
  OperationsIcon,
  SalesIcon,
  FinanceIcon,
  ScheduleBoardIcon,
} from "../../assets/svgs/svg-components";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import UserSessionService from "../../services/UserSessionService";
import AppModuleConfig from "../../assets/config/AppModuleConfig";

export const useAppUserMenuUtils = () => {
  /* user session dependencies */
  const getUserPermission = UserSessionService.getUserSessionPermission();
  /* user session dependencies */

  const tempMenuGroup = [];
  /* menuItemDashboard */
  const menuItemAdmin = {
    id: "admin",
    title: AppModuleConfig.ADMIN,
    url: AppRoutingConfig.APP_URL_ADMIN,
    icon: DashboardIcon,
  };
  getUserPermission?.adminDashboard && tempMenuGroup.push(menuItemAdmin);
  /* menuItemDashboard */

  /* menuItemHR */
  const menuItemHR = {
    id: "hr",
    title: AppModuleConfig.HR,
    url: AppRoutingConfig.APP_URL_HR,
    icon: HRIcon,
  };
  getUserPermission?.hR && tempMenuGroup.push(menuItemHR);
  /* menuItemHR */

  /* menuItemLeave */
  const menuItemLeave = {
    id: "leave",
    title: AppModuleConfig.HR,
    url: AppRoutingConfig.APP_URL_LEAVE,
    icon: HRIcon,
  };
  getUserPermission?.leave && tempMenuGroup.push(menuItemLeave);
  /* menuItemLeave */

  /* menuItemOperations */
  const menuItemOperations = {
    id: "operations",
    title: AppModuleConfig.OPERATIONS,
    url: AppRoutingConfig.APP_URL_OPERATIONS,
    icon: OperationsIcon,
  };
  getUserPermission?.operations && tempMenuGroup.push(menuItemOperations);
  /* menuItemOperations */

  /* menuItemSales */
  const menuItemSales = {
    id: "sales",
    title: AppModuleConfig.SALES,
    url: AppRoutingConfig.APP_URL_SALES,
    icon: SalesIcon,
  };
  getUserPermission?.sales && tempMenuGroup.push(menuItemSales);
  /* menuItemSales */

  /* menuItemCrm */
  const menuItemCrm = {
    id: "crm",
    title: AppModuleConfig.CRM,
    url: AppRoutingConfig.APP_URL_CRM,
    icon: CrmIcon,
  };
  getUserPermission?.cRM && tempMenuGroup.push(menuItemCrm);
  /* menuItemCrm */

  /* menuItemFleet */
  const menuItemFleet = {
    id: "fleet",
    title: AppModuleConfig.FLEET,
    url: AppRoutingConfig.APP_URL_FLEET,
    icon: FleetIcon,
  };
  getUserPermission?.fleet && tempMenuGroup.push(menuItemFleet);
  /* menuItemFleet */

  /* menuItemFinance */
  const menuItemFinance = {
    id: "finance",
    title: AppModuleConfig.FINANCE,
    url: AppRoutingConfig.APP_URL_FINANCE,
    icon: FinanceIcon,
  };
  getUserPermission?.finance && tempMenuGroup.push(menuItemFinance);
  /* menuItemFinance */

  // /* menuItemScheduleBoard */
  // const menuItemScheduleBoard = {
  //   id: "scheduleBoard",
  //   title: AppModuleConfig.SCHEDULE_BOARD,
  //   url: AppRoutingConfig.APP_URL_SCHEDULE_BOARD,
  //   icon: ScheduleBoardIcon,
  // };
  // tempMenuGroup.push(menuItemScheduleBoard);
  // /* menuItemScheduleBoard */

  return tempMenuGroup;
};
