import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import { Navigate } from "react-router";
import InfoConfig from "./info/InfoConfig";

const ScheduleBoardConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_SCHEDULE_BOARD,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_SCHEDULE_BOARD_INFO} replace />
      ),
    },
    ...InfoConfig.routes,
  ],
};

export default ScheduleBoardConfig;
