import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./scheduleBoardApis";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import { Dispatch } from "redux";

// GET JOBS LIST
export const getJobsAction =
  (dataToBeSent: {
    orderBy: boolean;
    pageNumber: number;
    pageSize: number;
    searchString: string;
    searchByJobStatus: null;
    searchByJobType: null;
    searchByIsPriority: boolean;
  }) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getJobs(dataToBeSent);
    if (response) {
      dispatch(
        Actions.createAction(Actions.GET_JOBS_LIST, { jobs: response?.data })
      );
    } else {
      dispatch(
        Actions.createAction(Actions.GET_JOBS_LIST, {
          jobs: { jobDetails: [], totalCount: 0 },
        })
      );
    }
    return response;
  };

/* GET JOB TYPE SELECT LIST */
export const getJobTypeSelectListAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getJobTypeSelectList();
    dispatch(
      Actions.createAction(Actions.GET_JOB_TYPE_SELECT_LIST, {
        jobsSelectList: response?.data,
      })
    );
    return response;
  };

/* GET JOB ID */
export const getJobIdAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getJobId();
    response &&
      dispatch(Actions.createAction(Actions.GET_JOB_ID, response?.data));
    return response;
  };

/* GET CUSTOMER NAME SELECT LIST */
export const getCustomerNameSelectListAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomerNameSelectList();

    response &&
      dispatch(
        Actions.createAction(Actions.GET_CUSTOMER_NAME_SELECT_LIST, {
          customerNameSelectList: response?.data,
        })
      );
    return response;
  };
/* GET CUSTOMER NAME SELECT LIST */

/* GET CUSTOMER NAME SELECT LIST */
export const getProjectByCustomerSelectListAction =
  (dataToBeSent: { customerId: number }) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any =
      await requestFromServer.getProjectByCustomerSelectList(dataToBeSent);

    response &&
      dispatch(
        Actions.createAction(Actions.GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST, {
          projectSelectList: response?.data,
        })
      );
    return response;
  };
/* GET CUSTOMER NAME SELECT LIST */

/* GET ALL STATE SELECT LIST */
export const getAllStatesSelectListAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getAllStatesSelectList();
    response &&
      dispatch(
        Actions.createAction(Actions.GET_ALL_STATES_SELECT_LIST, {
          statesSelectList: response?.data,
        })
      );
    return response;
  };

/* GET ALL STATE SELECT LIST */

/* GET ALL CITY SELECT LIST */
export const getCityByStateIdListAction =
  (dataToBeSent: { StateId: number }) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCityByStateIdList(
      dataToBeSent
    );
    response &&
      dispatch(
        Actions.createAction(Actions.GET_CITY_BY_STATE_ID_SELECT_LIST, {
          citiesSelectList: response?.data,
        })
      );
    return response;
  };
/* GET ALL CITY SELECT LIST */

/* ADD JOB */
export type AddJobTypes = {
  id: number;
  jobNo: string;
  customerId: number;
  projectId: number;
  jobTypeId: number;
  jobTitle: string;
  status: string;
  estimatedCost: number;
  actualCost: number;
  startDate: string;
  endDate: string;
  address: string;
  latitude: string;
  longitude: string;
  state: number;
  city: number;
  isPrority: boolean;
};
export const addJobAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddJobTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addJob(dataToBeSent);
    if (response) {
      navigate(AppRoutingConfig.APP_URL_SCHEDULE_BOARD_INFO);
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };

/* ADD JOB */

/* GET JOB BY ID */
export const getJobByIdAction =
  (dataToBeSent: { JobId: string }) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getJobById(dataToBeSent);
    return response;
  };
/* GET JOB BY ID */

// GET ALL JOB TIMELINE LIST
export const getAllJobTimelineListAction =
  (dataToBeSent: { startDate: string | null; endDate: string | null }) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getAllJobTimelineList(
      dataToBeSent
    );
    return response;
  };

// GET ALL JOB MAP LIST
export const getAllJobMapListAction =
  () =>
  async (dispatch: Dispatch): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getAllJobMapList();
    response &&
      dispatch(
        Actions.createAction(Actions.GET_ALL_JOB_MAP_LIST, {
          jobMapList: response?.data,
        })
      );
    return response;
  };

/* DELETE JOB */
export const deleteJobAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: {
      JobId: number;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.deleteJob(dataToBeSent);
    response &&
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    return response;
  };

// GET GOOGLE MAPS GEO CODE LIST
export const getGoogleMapsGeoCodeAction =
  (dataToBeSent: { latitude: number; longitude: number }) =>
  async (dispatch: Dispatch): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getGoogleMapsGeoCode(
      dataToBeSent
    );
    response &&
      dispatch(
        Actions.createAction(Actions.GET_GOOGLE_MAPS_GEOCODE_LIST, {
          googleMapsGeoCodeList: response?.data,
        })
      );
    return response;
  };

// GET GOOGLE MAPS PLACE ID LIST
export const getGoogleMapsPlaceIdAction =
  (dataToBeSent: { placeId: string }) =>
  async (dispatch: Dispatch): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getGoogleMapsPlaceId(
      dataToBeSent
    );
    return response;
  };
