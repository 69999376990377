import { useCallback, useEffect, useRef, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "../../../../common/select";
import Button from "../../../../common/button";
import TableLoader from "../../../../common/tableLoader";
import InputField from "../../../../common/inputField";
import Actions from "../../../../../redux/actions";
import {
  dateObjToDispViewIsoFormat,
  dateToTimestamp,
  getFilteredObjectFromId,
  isoToDispViewDate,
  isoToDispViewDateObj,
} from "../../../../../utils/appCommonUtils";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { useToast } from "../../../../../hooks/useToast";
import { allowCharactersOnlyForIntegerNumbers } from "../../../../../utils/appKeyStrokeLimitingUtils";
import Datepicker from "../../../../common/datepicker";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import IconButton from "../../../../common/iconButton";
import {
  IIcon,
  ToggleOffIcon,
  ToggleOnIcon,
} from "../../../../../assets/svgs/svg-components";
import {
  addJobAction,
  getAllStatesSelectListAction,
  getCityByStateIdListAction,
  getCustomerNameSelectListAction,
  getGoogleMapsGeoCodeAction,
  getGoogleMapsPlaceIdAction,
  getJobByIdAction,
  getJobIdAction,
  getJobTypeSelectListAction,
  getProjectByCustomerSelectListAction,
} from "../../../../../redux/services/scheduleBoard/scheduleBoardServices";
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import AppApiConfig from "../../../../../assets/config/AppApiConfig";
import { jobStatusSelectList } from "./Data";
import Tooltip from "../../../../common/tooltip";
import "./Schedule.scss";

type Props = {};

// init google map script
const initMapScriptAction = () => {
  const src = `${AppApiConfig.API_GOOGLE_MAPS_API_URL}?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&v=weekly`;
  return loadAsyncScript(src);
};

function loadAsyncScript(src: string) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const AddJobsForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  const { apiLoader } = useAppSelector((state) => state.loadingScreen);
  const [submitLoader, setSubmitLoader] = useState(false);
  /* loading dependencies */

  /* marker dependencies */
  const [markerCoordinates, setMarkerCoordinates] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 22.3072, lng: 73.1812 });
  /* marker dependencies */

  /* google maps dependencies */
  const addressSearchInputRef = useRef<any>(null);
  const autocompleteRef = useRef<any>(null);
  /* google maps dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  /* For navigating between pages */

  /* select list dependencies */
  const {
    customerNameSelectList,
    projectSelectList,
    jobsSelectList,
    statesSelectList,
    citiesSelectList,
  } = useAppSelector((state) => state.scheduleBoard);
  /* select list dependencies */

  const [priorityToggle, setPriorityToggle] = useState(false);

  /* Dependencies for default values and validation */
  type SelectListProps = { value: any; label: string };

  type DefaultValueTypes = {
    id: number;
    jobNo: string;
    customerId: null;
    projectId: null;
    jobTypeId: null;
    jobStatus: null;
    jobTitle: string;
    estimatedCost: string;
    actualCost: string;
    startDate: Date | null;
    endDate: Date | null;
    state: null;
    city: null;
  };

  const defaultValues: DefaultValueTypes = {
    id: 0,
    jobNo: "",
    customerId: null,
    projectId: null,
    jobTypeId: null,
    jobStatus: null,
    jobTitle: "",
    estimatedCost: "",
    actualCost: "",
    startDate: null,
    endDate: null,
    state: null,
    city: null,
  };

  const schema = yup.object().shape({
    projectId: yup
      .object()
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.ProjectIsRequired")
      ),
    jobTitle: yup
      .string()
      .max(100)
      .required(t("ScheduleBoard.PleaseEnterJobTitle")),
    estimatedCost: yup
      .string()
      .max(100)
      .required(t("ScheduleBoard.PleaseEnterEstimatedCost")),
    actualCost: yup
      .string()
      .max(100)
      .required(t("ScheduleBoard.PleaseEnterActualCost")),
    jobStatus: yup.object().required(t("ScheduleBoard.PleaseSelectJobStatus")),
    jobTypeId: yup.object().required(t("ScheduleBoard.PleaseSelectJobType")),
    customerId: yup.object().required(t("ScheduleBoard.CustomerIsRequired")),
    state: yup.object().required(t("ScheduleBoard.PleaseSelectState")),
    city: yup.object().required(t("ScheduleBoard.PleaseSelectCity")),
    startDate: yup
      .date()
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.StartDateIsRequired")
      )
      .test(
        "START_DATE_VALIDATION_CHECK",
        t("Operations.ProjectSummary.ProjectSummaryForm.StartDateValidation"),
        function (value) {
          const { endDate } = this.parent;
          if (
            dateToTimestamp(
              isoToDispViewDate(
                new Date(value),
                AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT
              )
            ) <=
            dateToTimestamp(
              isoToDispViewDate(
                new Date(endDate),
                AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT
              )
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
      ),
    endDate: yup
      .date()
      .required(
        t("Operations.ProjectSummary.ProjectSummaryForm.EndDateIsRequired")
      )
      .test(
        "END_DATE_VALIDATION_CHECK",
        t("Operations.ProjectSummary.ProjectSummaryForm.EndDateValidation"),
        function (value) {
          const { startDate } = this.parent;
          if (
            dateToTimestamp(
              isoToDispViewDate(
                new Date(value),
                AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT
              )
            ) >=
            dateToTimestamp(
              isoToDispViewDate(
                new Date(startDate),
                AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT
              )
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
      ),
  });

  const { handleSubmit, control, setValue, watch, reset, trigger, getValues } =
    useForm<DefaultValueTypes>({
      mode: "all",
      defaultValues,
      resolver: yupResolver(schema),
    });

  const form = watch();
  const { startDate } = form;
  /* Dependencies for default values and validation */

  const selectListApiCalls = (details: any) => {
    // Customer select list
    dispatch(getCustomerNameSelectListAction()).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.customerId, apiRes?.data).then(
          (customer: any) => {
            customer && loadDispatchStateForCustomer(customer);
            setValue("customerId", customer);
          }
        );
    });

    // Project select list based on customer
    const loadDispatchStateForCustomer = (customer: {
      value: any;
      label: string;
    }) => {
      // Project select list
      dispatch(
        getProjectByCustomerSelectListAction({ customerId: customer?.value })
      ).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(
            details?.projectId,
            apiRes?.data?.map(
              (element: {
                id: number;
                projectName: string;
                amount: number;
              }) => ({
                label: element.projectName,
                amount: element.amount,
                value: element.id,
              })
            )
          ).then((project: any) => {
            setValue("projectId", project);
          });
      });
    };

    // Job Type select list
    dispatch(getJobTypeSelectListAction()).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.jobTypeId, apiRes?.data).then(
          (jobType: any) => {
            setValue("jobTypeId", jobType);
          }
        );
    });

    // State select list based on country
    dispatch(getAllStatesSelectListAction()).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.state, apiRes?.data).then(
          (state: any) => {
            state && loadDispatchCityForState(state);
            setValue("state", state);
          }
        );
    });

    // City select list based on state
    const loadDispatchCityForState = (state: { value: any; label: string }) => {
      dispatch(getCityByStateIdListAction({ StateId: state?.value })).then(
        (apiRes) => {
          details &&
            apiRes?.data &&
            getFilteredObjectFromId(details?.city, apiRes?.data).then(
              (city: any) => {
                setValue("city", city);
              }
            );
        }
      );
    };
  };

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);
      //Generated Customer ID
      dispatch(getJobIdAction()).then((apiRes) => {
        setValue("jobNo", apiRes?.data);
      });
    }
  }, []);
  /* load initial dependencies - Add */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { jobId } = params;

      const dataToBeSent = { JobId: jobId };
      setLoadingComponent(true);
      dispatch(getJobByIdAction(dataToBeSent)).then((apiRes: any) => {
        const jobDetail = apiRes?.data;
        if (jobDetail) {
          selectListApiCalls(jobDetail[0]);

          reset({
            actualCost: jobDetail[0]?.actualCost,
            estimatedCost: jobDetail[0]?.estimatedCost,
            jobTitle: jobDetail[0]?.jobTitle,
            startDate: isoToDispViewDateObj(jobDetail[0]?.startDate),
            endDate: isoToDispViewDateObj(jobDetail[0]?.endDate),
            id: jobId,
            jobNo: jobDetail[0]?.jobNo,
          });
          geocodeLatLng(
            jobDetail[0]?.address,
            Number(jobDetail[0]?.latitude),
            Number(jobDetail[0]?.longitude)
          );

          setMarkerCoordinates({
            lat: Number(jobDetail[0]?.latitude),
            lng: Number(jobDetail[0]?.longitude),
          });
          setPriorityToggle(jobDetail[0]?.isPriority);

          getFilteredObjectFromId(
            jobDetail[0]?.status,
            jobStatusSelectList
          ).then((jobStatus: any) => {
            setValue("jobStatus", jobStatus);
          });
          setLoadingComponent(false);
        }
      });
    }
  }, []);
  /* load initial dependencies - Edit */

  /* On customer changed function */
  const onCustomerChanged = (
    option: SelectListProps,
    isClearAction: boolean
  ) => {
    dispatch(
      Actions.createAction(Actions.GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST, {
        projectSelectList: [],
      })
    );
    if (option && option !== null && option !== undefined) {
      loadProjectListBasedOnCustomer(option);
    }
    if (isClearAction) {
      dispatch(
        Actions.createAction(Actions.GET_PROJECT_BY_CUSTOMER_ID_SELECT_LIST, {
          projectSelectList: [],
        })
      );
    }
    setValue("estimatedCost", "");
    setValue("projectId", null);
  };
  /* On customer changed function */

  /* load project list based on customer name */
  const loadProjectListBasedOnCustomer = (option: {
    label: string;
    value: number;
  }) => {
    // Project select list
    dispatch(
      getProjectByCustomerSelectListAction({ customerId: option?.value })
    );
  };
  /* load project list based on customer name */

  /* On State changed function */
  const onStateChanged = (option: SelectListProps, isClearAction: boolean) => {
    if (option && option !== null && option !== undefined) {
      loadCityForState(option);
    }
    setValue("city", null);
  };
  /* On State changed function */

  /* for loading city code based on state */
  const loadCityForState = (option: SelectListProps) => {
    dispatch(getCityByStateIdListAction({ StateId: option?.value }));
  };
  /* for loading city code based on state */

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const geocodeLatLng = async (
    addressValue: string | null,
    lat: number,
    lng: number
  ) => {
    const dataToBeSent = {
      latitude: lat,
      longitude: lng,
    };
    dispatch(getGoogleMapsGeoCodeAction(dataToBeSent)).then((apiRes) => {
      const placeId = apiRes?.data?.results[0]?.place_id;
      const address = apiRes?.data?.results[0]?.formatted_address;
      if (addressValue !== null) {
        addressSearchInputRef.current.value = addressValue;
      } else {
        if (placeId) {
          getPlaceDetailsById(placeId);
        } else {
          addressSearchInputRef.current.value = address;
        }
      }
    });
  };

  const getPlaceDetailsById = async (placeId: string) => {
    const dataToBeSent = {
      placeId,
    };
    dispatch(getGoogleMapsPlaceIdAction(dataToBeSent)).then((apiRes) => {
      const address = apiRes?.data?.result?.formatted_address;
      addressSearchInputRef.current.value = address;
    });
  };

  const onLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(markerCoordinates);
    map.fitBounds(bounds);
    initMapScriptAction().then(() => initAutocomplete());
  };

  const onMapClick = (e: any) => {
    if (params?.isView) return;
    const currentCoordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    geocodeLatLng(null, currentCoordinates.lat, currentCoordinates.lng);
    setMarkerCoordinates(currentCoordinates);
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!addressSearchInputRef.current) return;

    if (autocompleteRef.current) {
      autocompleteRef.current.unbindAll();
      autocompleteRef.current = null;
    }

    autocompleteRef.current = new window.google.maps.places.Autocomplete(
      addressSearchInputRef.current,
      {
        fields: ["address_components", "geometry"],
      }
    );
    autocompleteRef.current.addListener("place_changed", () => {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        setMarkerCoordinates({
          lat: Number(place?.geometry?.location?.lat()),
          lng: Number(place?.geometry?.location?.lng()),
        });
      }
    });
  };

  // Initialize autocomplete on focus
  const handleFocus = () => {
    if (!autocompleteRef.current) {
      initAutocomplete();
    }
  };

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const showInfoWindow = () => {
    setInfoWindowOpen(true);
  };

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    const {
      customerId,
      city,
      state,
      endDate,
      startDate,
      jobStatus,
      jobTypeId,
      projectId,
      estimatedCost,
      actualCost,
      ...rest
    } = formData;
    if (!addressSearchInputRef.current?.value) {
      showToast({
        message: "Please enter address line 1",
        variant: AppConstConfig.TOAST_VARIANT_ERROR,
      });
      return;
    }
    if (!estimatedCost) {
      showToast({
        message: "Selected Project does not contain an estimated cost",
        variant: AppConstConfig.TOAST_VARIANT_ERROR,
      });
      return;
    }
    setSubmitLoader(true);

    const dataToBeSent = {
      ...rest,
      customerId: customerId?.value,
      projectId: projectId?.value,
      city: city?.value,
      state: state?.value,
      jobTypeId: jobTypeId?.value,
      status: jobStatus?.value,
      startDate: dateObjToDispViewIsoFormat(startDate),
      endDate: dateObjToDispViewIsoFormat(endDate),
      isPriority: priorityToggle,
      latitude: markerCoordinates?.lat?.toString(),
      longitude: markerCoordinates?.lng?.toString(),
      estimatedCost: Number(estimatedCost),
      actualCost: Number(actualCost),
      address: addressSearchInputRef.current?.value,
    };

    dispatch(addJobAction(navigate, showToast, dataToBeSent)).then(() => {
      setSubmitLoader(false);
    });
  };
  /* Form submit handler */

  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="jobsForm"
        id="jobsForm"
        className="jobs-form"
      >
        <Grid container spacing="md" flexDirection="column">
          {/* Customer Info */}
          <Grid item>
            <Card>
              <div className="add-jobs-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>{t("ScheduleBoard.AddJobs")}</Title>
                </Grid>

                <Grid container spacing="sm">
                  {/* Id */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.JobId")}</Label>
                    <InputField
                      name="jobNo"
                      disabled
                      placeholder={t("ScheduleBoard.EnterJobId")}
                      control={control}
                    />
                  </Grid>

                  {/* Customer */}
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.Customer")}</Label>
                    <Select
                      name="customerId"
                      options={customerNameSelectList}
                      control={control}
                      placeholder={t("ScheduleBoard.SelectCustomer")}
                      onCustomChange={onCustomerChanged}
                      isClearable
                      isDisabled={params?.isView}
                    />
                  </Grid>

                  {/* Project */}
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label required>
                      {t("Operations.WorkFlow.ProjectSchedulingForm.Project")}
                    </Label>
                    <Select
                      name="projectId"
                      options={projectSelectList}
                      control={control}
                      placeholder={t(
                        "Operations.WorkFlow.ProjectSchedulingForm.SelectProject"
                      )}
                      isClearable
                      onCustomChange={(option: any, isClearAction: boolean) => {
                        setValue("estimatedCost", option?.amount);
                        if (isClearAction) {
                          setValue("estimatedCost", "");
                        }
                      }}
                      isDisabled={params?.isView}
                    />
                  </Grid>

                  {/* Job Type */}
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.JobType")}</Label>
                    <Select
                      name="jobTypeId"
                      options={jobsSelectList}
                      control={control}
                      placeholder={t("ScheduleBoard.SelectJobType")}
                      isDisabled={params?.isView}
                    />
                  </Grid>

                  {/* Job Title */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.JobTitle")}</Label>
                    <InputField
                      name="jobTitle"
                      placeholder={t("ScheduleBoard.EnterJobTitle")}
                      control={control}
                      disabled={params?.isView}
                    />
                  </Grid>

                  {/* Status */}
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.JobStatus")}</Label>
                    <Select
                      name="jobStatus"
                      options={jobStatusSelectList}
                      control={control}
                      placeholder={t("ScheduleBoard.SelectJobStatus")}
                      isDisabled={params?.isView}
                    />
                  </Grid>

                  {/* Estimated Cost */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <div className="estimated-cost-label-container">
                      <Label required style={{ width: "fit-content" }}>
                        {t("ScheduleBoard.EstimatedCost")}
                      </Label>
                      <div className="estimated-cost-tooltip">
                        <Tooltip
                          title={
                            "Default value would be project's (editable) cost"
                          }
                          style={{ width: "fit-content" }}
                        >
                          <IconButton
                            style={{ padding: 3 }}
                            onClick={(e) => e.preventDefault()}
                          >
                            <IIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <InputField
                      name="estimatedCost"
                      disabled
                      onKeyDown={(e) => allowCharactersOnlyForIntegerNumbers(e)}
                      control={control}
                    />
                  </Grid>

                  {/* Actual Cost */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.ActualCost")}</Label>
                    <InputField
                      name="actualCost"
                      onKeyDown={(e) => allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t("ScheduleBoard.EnterEstimatedCost")}
                      control={control}
                      disabled={params?.isView}
                    />
                  </Grid>

                  {/* START DATE */}
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.ProjectSummary.ProjectSummaryForm.StartDate"
                      )}
                    </Label>
                    <Datepicker
                      name="startDate"
                      onCustomChange={async () => await trigger("endDate")}
                      control={control}
                      isClearable={params?.isView ? false : true}
                      disabled={params?.isView}
                    />
                  </Grid>
                  {/* START DATE */}

                  {/* END DATE */}
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label required>
                      {t(
                        "Operations.ProjectSummary.ProjectSummaryForm.EndDate"
                      )}
                    </Label>
                    <Datepicker
                      name="endDate"
                      minDate={startDate}
                      onCustomChange={async () => await trigger("startDate")}
                      control={control}
                      isClearable={params?.isView ? false : true}
                      disabled={params?.isView}
                    />
                  </Grid>
                  {/* END DATE */}

                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Label>{t("ScheduleBoard.Priority")}</Label>
                    <IconButton
                      onClick={(e) =>
                        setPriorityToggle((prevValue) => !prevValue)
                      }
                      type="button"
                      disabled={params?.isView}
                    >
                      {priorityToggle ? <ToggleOnIcon /> : <ToggleOffIcon />}
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <div className="location-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>{t("ScheduleBoard.Location")}</Title>
                </Grid>

                <Grid container spacing="sm">
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>{t("ScheduleBoard.AddressLine1")}</Label>
                    <input
                      placeholder={t("CommonUtils.Search")}
                      ref={addressSearchInputRef}
                      className="google-location-input"
                      onFocus={handleFocus}
                      disabled={params?.isView}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="google-maps-dialog-container">
                      {!isLoaded ? (
                        <TableLoader />
                      ) : (
                        <GoogleMap
                          mapContainerClassName="map-container"
                          center={markerCoordinates}
                          zoom={10}
                          onLoad={onLoad}
                          onClick={onMapClick}
                        >
                          <MarkerF
                            position={markerCoordinates}
                            onClick={showInfoWindow}
                          >
                            {infoWindowOpen && (
                              <InfoWindow
                                onCloseClick={() => setInfoWindowOpen(false)}
                              >
                                <h1>Hi I am Info Window</h1>
                              </InfoWindow>
                            )}
                          </MarkerF>
                        </GoogleMap>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("HR.EmployeeManagement.EmployeeManagementForm.State")}
                    </Label>
                    <Select
                      name="state"
                      options={statesSelectList}
                      control={control}
                      onCustomChange={(option, isClearAction) => {
                        onStateChanged(option, isClearAction);
                      }}
                      isLoading={apiLoader}
                      isClearable
                      placeholder={t(
                        "HR.EmployeeManagement.EmployeeManagementForm.SelectState"
                      )}
                      isDisabled={params?.isView}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("HR.EmployeeManagement.EmployeeManagementForm.City")}
                    </Label>
                    <Select
                      name="city"
                      options={citiesSelectList}
                      control={control}
                      isLoading={apiLoader}
                      isClearable
                      placeholder={t(
                        "HR.EmployeeManagement.EmployeeManagementForm.SelectCity"
                      )}
                      isDisabled={params?.isView}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    {!params?.isView && (
                      <>
                        <Button
                          type="submit"
                          form="jobsForm"
                          loading={submitLoader}
                          disabled={submitLoader}
                        >
                          {t("CommonUtils.Button.Submit")}
                        </Button>
                        <Button
                          variant="outlined"
                          type="button"
                          onClick={() => {
                            const currentValues = getValues();
                            const preservedValues = {
                              jobNo: currentValues.jobNo,
                            };
                            reset({ ...preservedValues });
                          }}
                        >
                          {t("CommonUtils.Button.Reset")}
                        </Button>
                      </>
                    )}

                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigate(
                          AppRoutingConfig.APP_URL_OPERATIONS_SCHEDULE_MODULE_LIST
                        );
                      }}
                      type="button"
                    >
                      {t("CommonUtils.Button.Back")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddJobsForm;
