import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";
import AddJobsForm from "./AddJobsForm";

const Schedule = lazy(() => import("./Schedule"));

const ScheduleConfig = {
  routes: [
    {
      index: true,
      element: (
        <Navigate
          to={AppRoutingConfig.APP_URL_OPERATIONS_SCHEDULE_MODULE_LIST}
          replace
        />
      ),
    },
    {
      path: AppRoutingConfig.APP_URL_OPERATIONS_SCHEDULE_MODULE_LIST,
      element: <Schedule />,
      handle: { crumb: () => "Schedule" },
    },
    {
      path: AppRoutingConfig.APP_URL_OPERATIONS_SCHEDULE_MODULE_MOD,
      element: <AddJobsForm />,
      handle: { crumb: () => "Add Jobs" },
    },
  ],
};

export default ScheduleConfig;
